import { mapGetters, mapState } from 'vuex'

export default {
	data() {
		return {
			loginType: 'username',
			currentPage: 'login',
			accountHolderLoading: false,
			acceptCondition: true,
			showPassword: false,
			formValid: false,
			formData: {
				password: '',
				reg_fingerprint: '',
				currency: '',
				phone: '',
				bank_id: '',
				bank_account_number: '',
				account_holder_name: '',
				recaptchaToken: '',
				language: 'th-TH',
			},
			accountNameReadonly: true,
			showAccountHolderNameInstruction: false,
			suspendRegistrationLoading: false,
			registrationOptionLoading: false,
			currencyIsSuspended: false,
			registrationOption: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank
			currency: [],
			isRequireRule: true,
		}
	},
	computed: {
		modal: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
		...mapState({
			supportCountryAndLanguage: (state) =>
				state.settings.supportCountryAndLanguage,
		}),
		...mapGetters({
			banks: 'banks/banks',
			supportCountries: 'themes/settings/supportCountry',
		}),
		formRule() {
			return {
				password: [
					(v) => !!v || this.tl('passwordIsRequired'),
					(v) =>
						(v && v.length >= 6) || this.tl('passwordGreaterThan'),
				],
				currency: [(v) => !!v || `${this.tl('currencyIsRequired')}`],
				country: [(v) => !!v || `${this.tl('countryIsRequired')}`],
				phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
				bank_id: [(v) => !!v || `${this.tl('bankIsRequired')}`],
				bank_account_number: [
					(v) => !!v || `${this.tl('accountNumberIsRequired')}`,
					(v) =>
						/^[\d\s]+$/.test(v) ||
						`${this.tl('accountNumberShouldConatainOnlyNumber')}`,
				],

				account_holder_name: [
					(v) => !!v || `${this.tl('accountHolderNameIsRequired')}`,
				],
				bank_name: [(v) => !!v || `${this.tl('bankNameIsRequired')}`],
				agree: [(v) => !!v || ``],
			}
		},
	},
	methods: {
		async getAccountHolderName() {
			if (!this.formData.bank_id || !this.formData.bank_account_number) return
			this.accountHolderLoading = true
			const result = await this.$store.dispatch(
				'themes/player/getBankAccountHolderName',
				{
					bankId: this.formData.bank_id,
					accountNo: this.formData.bank_account_number,
				}
			)
			this.accountHolderLoading = false
			if (result) {
				if (!result.requireManualInput) {
					this.formData.account_holder_name = result?.fullName
					this.accountNameReadonly = true
					this.showAccountHolderNameInstruction = false
					return
				}
				this.accountNameReadonly = false
				this.showAccountHolderNameInstruction = true
			}
			this.formData.account_holder_name = ''
		},
		changeRequiredRule(rule) {
			this.isRequireRule = rule
		},
		onChangeBank(bankId) {
			this.getAccountHolderName()
			const getSelectedBank = this.banks.find((el) => el.id === bankId)
			if (getSelectedBank?.supported_currency) {
				this.currency = getSelectedBank.supported_currency
			}
		},
		async onChangeLanguageString(code) {
			if (
				this.$cookie?.get('reg_loading') &&
				this.$cookie?.get('country_code') !== code
			) {
				this.$store.commit('settings/changeLanguageLoading', true)
			} else {
				this.$cookie.set('reg_loading', true)
			}
			const filter = this.supportCountryAndLanguage.find(
				(el) => el.country_code === code
			)

			if (this.$cookie?.get('currency') !== filter?.currency_code) {
				this.$cookie.set('currency', filter?.currency_code)
			}

			this.$store.commit('settings/setGlobalFlag', filter?.country_code)

			if (this.$cookie?.get('country_code') !== code) {
				this.$cookie.set('country_code', filter.country_code)
				this.$store.commit(
					'settings/setDefaultCurrency',
					filter.currency_code
				)
				await this.globalBrowserDefaultLanguage()
			}
			await this.$store.dispatch('cms/cms', {})
			this.$store.commit('settings/changeLanguageLoading', false)
		},
		countryChange(e) {
			this.onChangeLanguageString(e)
			this.$store.dispatch('banks/banks', { country_code: e })
			const selectedCountry = this.supportCountryAndLanguage.find(
				(el) => el.country_code === e
			)
			if (selectedCountry) {
				const countryCurrency = selectedCountry.currency_code
				this.formData.currency = countryCurrency

				// fire to check suspend and player registration status
				this.onChangeCurrency(countryCurrency)
			}
		},
		onChangeCurrency(currency) {
			this.checkSuspendRegistration(currency)
			this.checkRegistrationSetting(currency)
		},
		async checkSuspendRegistration(currency) {
			this.suspendRegistrationLoading = true
			const response = await this.$store.dispatch(
				'themes/player/checkSuspendRegistration',
				currency
			)

			if (response) {
				const { status } = response
				this.currencyIsSuspended = status
				if (status) {
					this.$toast.error(this.tl('registrationIsNotAvailable'))
				}
			}
			this.suspendRegistrationLoading = false
		},
		async checkRegistrationSetting(currency) {
			this.registrationOptionLoading = true
			const response = await this.$store.dispatch(
				'themes/player/checkRegistrationSetting',
				currency
			)

			if (response) {
				const { registration_type: regType } = response
				if (regType) {
					if ([2, 3, 4].includes(~~regType)) {
						this.accountNameReadonly = false
					} else {
						this.accountNameReadonly = true
					}

					this.registrationOption = ~~regType
				}
			}
			this.registrationOptionLoading = false
		},
		isActiveLanguage(item) {
			return item?.supported_languages?.find(
				(el) => el?.is_active && el?.is_default
			)
		},
	},
}