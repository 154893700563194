export default {
	props: {
		hideDetails: {
			type: [Boolean, String],
			default: null,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		value: {
			type: String,
			default: '',
		},
		currentValue: {
			type: String,
			default: '',
		},
		color: {
			type: String,
			default: null,
		},
		outlined: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		defaultCountry: {
			type: String,
			default: '',
		},
		isRequireRule: {
			type: Boolean,
			default: true,
		},
		showErrorOnBlur: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		phone: null,
		generalInquiryValid: true,
		isFirstTypingPhone: true,
	}),
	computed: {
		formRule() {
			return this.isRequireRule
				? [
					(v) =>
						!v ||
						/^[\d\s ()+\\-]+$/.test(v) ||
						this.tl('phoneNumberFormatInvalid'), // this.tl('phoneNumberMustNotContainChar'),
					(v) =>
						!v ||
						this.generalInquiryValid ||
						this.tl('phoneNumberFormatInvalid'),
					...this.rules,
				]
				: []
		},
		allowPhoneToDisplayErrorMessage() {
			return !this.isFirstTypingPhone
		},
	},
	watch: {
		value(value) {
			this.phone = value?.toString()
		},
	},
	mounted() {
		this.phone = this.value?.toString()
	},
	methods: {
		handleChangeRequire(isRequire) {
			if (this.showErrorOnBlur) {
				this.$emit('update', isRequire)
			}
		},
		onPhoneTextBlur(value) {
			// Explanation of the logic:
			/**
			 * Initially, when you start typing your phone number, the error message won't appear.
			 * It only shows after the phone input loses focus (onBlur event). At that point, if the phone number is invalid,
			 * the WRONG_PHONE error message is displayed.
			 *
			 * The reason for checking `if(this.phone)` is to prevent displaying the WRONG_PHONE error message
			 * in a specific case: when the user clicks on the phone input, doesn't type anything, and then clicks outside the input.
			 * If they later return to type in the input, we avoid showing the error prematurely.
			 */
			if (this.phone)
				this.isFirstTypingPhone = false
			this.handleChangeRequire(value)
		},
	}
}