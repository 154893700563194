import { mapGetters, mapState } from 'vuex'

export default {
	data() {
		return {
			acceptCondition: true,
			showPassword: false,
			formValid: false,
			form: {
				password: '',
				reg_fingerprint: '',
				currency: null,
				phone: '',
				bank_id: '',
				bank_account_number: '',
				account_holder_name: '',
				recaptchaToken: '',
				language: 'th-TH',
				country_code: null,
			},
			registerLoading: false,
			suspendRegistrationLoading: false,
			registrationOptionLoading: false,
			currencyIsSuspended: false,
			reg_flow: 1, // Definitions: 1 = normal, 2 or 3 = no bank & account, 4 no everything about bank, 5 only crypto
			currencies: [],
			currency: [],
			playerRegistrationType: 1,
			bankNameType: 1,
			bankOptionSelection: 1,
			firstChar: false,
			playerIdLength: false,
			underscore: false,
			formatStr: false,
			isShow: false,
			globalReg: null,
			isRequireRule: true,
		}
	},
	computed: {
		...mapState({
			supportCountryAndLanguage: (state) =>
				state.settings.supportCountryAndLanguage,
			operationSetting: (state) => state.settings.operationSetting,
		}),
		...mapGetters({
			registrationImage: 'cms/registrationImage',
			banks: 'banks/banks',
			supportCountries: 'themes/settings/supportCountry',
		}),
		phoneCountryCode() {
			return this.form.country_code === 'GO'
				? this.globalReg
				: this.form.country_code
		},
		formRule() {
			return {
				password: [
					(v) => !!v || this.tl('passwordIsRequired'),
					(v) =>
						(v && v.length >= 6) || this.tl('passwordGreaterThan'),
				],
				currency: [(v) => !!v || `${this.tl('currencyIsRequired')}`],
				country: [(v) => !!v || `${this.tl('countryIsRequired')}`],
				phone: [(v) => !!v || `${this.tl('phoneNumberIsRequired')}`],
				bank_id: [(v) => !!v || `${this.tl('bankIsRequired')}`],
				bank_account_number: [
					(v) => !!v || `${this.tl('accountNumberIsRequired')}`,
					(v) =>
						/^[\d\s]+$/.test(v) ||
						`${this.tl('accountNumberShouldContainOnlyNumber')}`,
				],
				player_id: [(v) => !!v || `${this.tl('playerIdIsRequired')}`],
				account_holder_name: [
					(v) => !!v || `${this.tl('accountHolderNameIsRequired')}`,
				],
				bank_name: [(v) => !!v || `${this.tl('bankNameIsRequired')}`],
				agree: [(v) => !!v || ``],
			}
		},
	},
	methods: {
		inputPlayerId(e) {
			this.form.player_id = e.trim().toLowerCase()
			const step1Regex = /^[a-z]/
			const step3Regex = /^[a-z][a-z0-9]{3,10}[a-z0-9]$/
			if (this.form.player_id?.length >= 1) this.isShow = true
			else this.isShow = false
			if (step1Regex.test(this.form.player_id.charAt(0))) this.firstChar = true
			else this.firstChar = false
			if (this.form.player_id?.length > 1 && this.form.player_id.includes('_')) this.underscore = true
			else this.underscore = false

			this.playerIdLength = this.form.player_id?.length >= 5 && this.form.player_id?.length <= 12

			const isValidStep3 = step3Regex.test(this.form.player_id)
			if (isValidStep3) this.formatStr = true
			else this.formatStr = false
		},
		countryChange(e) {
			this.onChangeLanguageString(e)

			if (e === 'GO') {
				this.$store.dispatch('settings/getDefaultCurrency').then(() => {
					const globalReg = this.$cookie?.get('global_reg')

					this.globalReg = globalReg
				})
			}

			this.$store.dispatch('banks/banks', { country_code: e })

			const selectedCountry = this.supportCountryAndLanguage.find(
				(el) => el.country_code === e
			)

			if (selectedCountry) {
				const countryCurrency = selectedCountry.currency_code
				this.form.currency =
					countryCurrency || this.$cookie.get('currency')
				this.form.bank_currency = countryCurrency
				// fire to check suspend and player registration status
				this.onChangeCurrency(countryCurrency)
			}
		},
		async onChangeLanguageString(code) {
			if (
				this.$cookie.get('reg_loading') &&
				this.$cookie.get('country_code') !== code
			) {
				this.$store.commit('settings/changeLanguageLoading', true)
			} else {
				this.$cookie.set('reg_loading', true)
			}
			const filter = this.supportCountryAndLanguage?.find(
				(el) => el.country_code === code
			)

			if (filter?.currency_code) {
				if (this.$cookie?.get('currency') !== filter?.currency_code) {
					this.$cookie.set('currency', filter?.currency_code)
				}
			}

			this.$store.commit('settings/setGlobalFlag', filter?.country_code)

			if (this.$cookie?.get('country_code') !== code) {
				this.$cookie.set('country_code', filter?.country_code)
				this.$store.commit(
					'settings/setDefaultCurrency',
					filter?.currency_code
				)
				await this.globalBrowserDefaultLanguage()
			}
			this.$store.commit('settings/changeLanguageLoading', false)
		},
		async onChangeCurrency(currency) {
			await this.onOperationSetting(currency)
			this.checkSuspendRegistration(currency)
		},
		async onOperationSetting(currency) {
			await this.$store.dispatch('settings/operationSetting', currency)
		},
		async checkSuspendRegistration(currency) {
			this.suspendRegistrationLoading = true
			const response = await this.$store.dispatch(
				'themes/player/checkSuspendRegistration',
				currency
			)

			if (response) {
				const { status } = response
				this.currencyIsSuspended = status
				if (status) {
					this.$toast.error(this.tl('registrationIsNotAvailable'))
				}
			}

			this.suspendRegistrationLoading = false
		},
		changeRequiredRule(rule) {
			this.isRequireRule = rule
		},
	},
}